import type {CodeSection} from '@github-ui/code-nav'
import type {SafeHTMLString} from '@github-ui/safe-html'
import {useCodeViewOptions} from '@github-ui/use-code-view-options'
import {Box, Link} from '@primer/react'
import React from 'react'

import {useCurrentBlob} from '../../../../hooks/CurrentBlob'
import type {SetStickyLinesType} from '../../../../hooks/use-sticky-lines'
import {numberOfLinesPerContentVisibility} from './CodeLinesNoVirtualization'
import {HighlightedLinesOverlay} from './HighlightedLinesOverlay'
import {HighlightedSymbolsOverlay} from './HighlightedSymbolsOverlay'
import type {CodeLineData} from './hooks/use-code-lines'
import {LineNumberNoVirtualziation} from './LineNumberNoVirtualization'
import {SyntaxHighlightedOverlay} from './SyntaxHighlightedLine'

interface CodeLinesSSRNoVirtualizationProps {
  linesData: CodeLineData[]
  tabSize: number
  onCollapseToggle: () => void
  colorizedLines: SafeHTMLString[] | null
  nonTruncatedLinesData: CodeLineData[]
  codeSections?: Map<number, CodeSection[]>
  codeLineToSectionMap?: Map<number, CodeSection[]>
  contentWidth?: number
  copilotAccessAllowed: boolean
  onLineNumberClick?: React.MouseEventHandler<HTMLDivElement>
  onLineStickOrUnstick?: SetStickyLinesType
}

export const CodeLinesSSRNoVirtualization = React.memo(CodeLinesSSRNoVirtualizationUnmemoized)

function CodeLinesSSRNoVirtualizationUnmemoized({
  linesData,
  onLineNumberClick,
  codeSections,
  nonTruncatedLinesData,
  colorizedLines,
  onLineStickOrUnstick,
  tabSize,
  contentWidth,
  copilotAccessAllowed,
  onCollapseToggle,
}: CodeLinesSSRNoVirtualizationProps) {
  const wrapOptionEnabled = useCodeViewOptions().codeWrappingOption.enabled
  const {rawBlobUrl} = useCurrentBlob()
  //we want to wrap every 60 lines in a data-visibility wrapper, so we need
  //to make this array to map over down below
  const lineArrayMap = [...Array(Math.floor(linesData.length / numberOfLinesPerContentVisibility) + 1).keys()]

  return (
    <Box
      className="react-code-file-contents"
      role="presentation"
      aria-hidden
      data-tab-size={tabSize}
      data-paste-markdown-skip
      sx={{
        tabSize,
        isolation: 'isolate',
        position: 'relative',
        width: contentWidth,
        overflow: 'auto',
        maxWidth: wrapOptionEnabled ? '100%' : 'unset',
      }}
      data-hpc
    >
      <div
        className="react-line-numbers-no-virtualization"
        style={{
          pointerEvents: 'auto',
          position: 'relative',
          zIndex: 2,
        }}
      >
        {lineArrayMap.map(segmentCount => {
          const tempLines = linesData.slice(
            segmentCount * numberOfLinesPerContentVisibility,
            Math.min(
              segmentCount * numberOfLinesPerContentVisibility + numberOfLinesPerContentVisibility,
              linesData.length,
            ),
          )
          return (
            <div
              key={`line-number-wrapper-${segmentCount}-content:${tempLines[0]?.rawText?.substring(0, 100)}`}
              className="react-no-virtualization-wrapper-lines-ssr"
            >
              {tempLines.map(lineData => {
                return (
                  <LineNumberNoVirtualziation
                    codeLineData={lineData}
                    key={`line-number-${lineData.lineNumber}`}
                    onClick={onLineNumberClick}
                    ownedCodeSections={codeSections}
                    onLineStickOrUnstick={onLineStickOrUnstick}
                    onCollapseToggle={onCollapseToggle}
                  />
                )
              })}
            </div>
          )
        })}
      </div>
      <div className="react-code-lines">
        <HighlightedSymbolsOverlay linesData={linesData} />
        <HighlightedLinesOverlay linesData={linesData} copilotAccessAllowed={copilotAccessAllowed} />
        <SyntaxHighlightedOverlay linesData={nonTruncatedLinesData} colorizedLines={colorizedLines} />
        {linesData.length === 1000 && (
          <Box sx={{justifyContent: 'center', display: 'flex'}}>
            <Link href={rawBlobUrl}>View remainder of file in raw view</Link>
          </Box>
        )}
      </div>
    </Box>
  )
}

try{ CodeLinesSSRNoVirtualization.displayName ||= 'CodeLinesSSRNoVirtualization' } catch {}
try{ CodeLinesSSRNoVirtualizationUnmemoized.displayName ||= 'CodeLinesSSRNoVirtualizationUnmemoized' } catch {}