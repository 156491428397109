import type {CopilotChatReference} from '@github-ui/copilot-chat/utils/copilot-chat-types'
import {handleAddReference} from '@github-ui/copilot-code-chat/CopilotCodeLinesMenu'
import {ActionList} from '@primer/react'

export default function CopilotMenuItems({fileReference}: {fileReference: CopilotChatReference}) {
  return (
    <ActionList.Item data-testid="copilot-menu-items" onSelect={() => handleAddReference(fileReference)}>
      Ask about this file
    </ActionList.Item>
  )
}

try{ CopilotMenuItems.displayName ||= 'CopilotMenuItems' } catch {}