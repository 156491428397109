import type {CodeNavigationInfo, CodeSymbol, DefinitionOrReference} from '@github-ui/code-nav'
import {useFilesPageInfo} from '@github-ui/code-view-shared/contexts/FilesPageInfoContext'
import {SymbolChangedEvent} from '@github-ui/copilot-chat/utils/copilot-chat-events'
import type {CopilotChatReference} from '@github-ui/copilot-chat/utils/copilot-chat-types'
import {useCurrentRepository} from '@github-ui/current-repository'
import {qualifyRef, type RefType} from '@github-ui/ref-utils'
import {useEffect, useMemo, useState} from 'react'

import {useCurrentBlob} from '../../../../hooks/CurrentBlob'
import {useFocusSymbolPane} from '../../../../hooks/use-focus-symbol-pane'
import {CodeNavSymbolDetails} from './CodeNavSymbolDetails'
import {CodeNavSymbolNavigation} from './CodeNavSymbolNavigation'

export function CodeNavInfoPanel({
  selectedText,
  showCodeNavWithSymbol,
  lineNumber,
  offset,
  onClose,
  onClear,
  codeNavInfo,
  isLoading,
  setSearchResults,
  setFocusedSearchResult,
  autoFocusSearch,
}: {
  selectedText: string
  lineNumber: number
  offset: number
  onClose: () => void
  onClear: () => void
  showCodeNavWithSymbol: (symbolData: CodeSymbol) => void
  codeNavInfo: CodeNavigationInfo
  isLoading: boolean
  setSearchTerm: (term: string) => void
  setSearchResults: (results: DefinitionOrReference[]) => void
  setFocusedSearchResult: (idx: number | undefined) => void
  autoFocusSearch: boolean
}) {
  function onSymbolSelect(sym: CodeSymbol) {
    showCodeNavWithSymbol(sym)
    setShowSymbolTree(false)
  }

  const [showSymbolTree, setShowSymbolTree] = useState(selectedText ? false : true)
  useFocusSymbolPane(focusSymbolSearch => {
    if (focusSymbolSearch) {
      onClear()
      setShowSymbolTree(true)
      setSearchResults([])
    }
  })

  const {refInfo, path} = useFilesPageInfo()
  const repo = useCurrentRepository()
  const {language, languageID, displayUrl} = useCurrentBlob()
  const fileReference: CopilotChatReference = useMemo(
    () => ({
      type: 'file',
      languageID,
      languageName: language,
      path,
      ref: qualifyRef(refInfo.name, refInfo.refType as RefType),
      commitOID: refInfo.currentOid,
      repoID: repo.id,
      repoName: repo.name,
      repoOwner: repo.ownerLogin,
      url: displayUrl,
    }),
    [
      languageID,
      language,
      path,
      refInfo.name,
      refInfo.refType,
      refInfo.currentOid,
      repo.id,
      repo.name,
      repo.ownerLogin,
      displayUrl,
    ],
  )

  useEffect(() => {
    if (selectedText) {
      setShowSymbolTree(false)
    } else if (!showSymbolTree) {
      window.dispatchEvent(new SymbolChangedEvent(fileReference))
      setShowSymbolTree(true)
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedText, codeNavInfo])

  return (
    <div id="symbols-pane">
      {showSymbolTree ? (
        codeNavInfo ? (
          <CodeNavSymbolNavigation
            treeSymbols={codeNavInfo.symbolTree}
            onSymbolSelect={onSymbolSelect}
            codeSymbols={codeNavInfo.symbols}
            onClose={onClose}
            autoFocusSearch={autoFocusSearch}
          />
        ) : (
          // TODO: Add zero state for when there is no code nav
          <div>Click on a symbol to see code navigation data</div>
        )
      ) : (
        <CodeNavSymbolDetails
          codeNavInfo={codeNavInfo}
          selectedText={selectedText}
          lineNumber={lineNumber}
          offset={offset}
          onBackToSymbol={() => {
            onClear()
            setShowSymbolTree(true)
            setSearchResults([])
            window.dispatchEvent(new SymbolChangedEvent(fileReference))
          }}
          onClose={() => {
            onClose()
            onClear()
            setSearchResults([])
            window.dispatchEvent(new SymbolChangedEvent(fileReference))
          }}
          onSymbolSelect={onSymbolSelect}
          isLoading={isLoading}
          setSearchResults={setSearchResults}
          setFocusedSearchResult={setFocusedSearchResult}
        />
      )}
    </div>
  )
}

try{ CodeNavInfoPanel.displayName ||= 'CodeNavInfoPanel' } catch {}