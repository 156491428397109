import {useCurrentLineHeight} from '../../../../hooks/use-current-line-height'
import {getActualLineNumberBinarySearch} from '../../../../utilities/lines'
import {HighlightedOverlay} from '../HighlightedOverlay'
import type {CodeLineData} from './hooks/use-code-lines'
import {useAllSearchResults, useFocusedSearchResultWithoutLineCheck} from './SearchResultsProvider'

export function HighlightedSymbolsOverlay({linesData}: {linesData: CodeLineData[]}) {
  const results = useAllSearchResults()
  const focusedResult = useFocusedSearchResultWithoutLineCheck()
  const lineHeight = useCurrentLineHeight('react-line-numbers')
  return (
    <>
      {[...results.values()].map(definitionsOrReferences => {
        const lineNumber = definitionsOrReferences[0]?.lineNumber
        if (lineNumber === undefined) return null
        const actualLineNumber = getActualLineNumberBinarySearch(lineNumber, linesData) + 1

        return (
          <HighlightedOverlay
            key={`overlay-${actualLineNumber}-lineNumber-no-virtualization`}
            className="symbol-highlight react-code-text"
            lineNumber={actualLineNumber}
            symbols={definitionsOrReferences}
            focusedSymbol={focusedResult}
            sx={{
              position: 'absolute',
              top: lineHeight * (actualLineNumber - 1),
              pl: '10px',
              height: lineHeight,
              whiteSpace: 'pre',
            }}
          />
        )
      })}
    </>
  )
}

try{ HighlightedSymbolsOverlay.displayName ||= 'HighlightedSymbolsOverlay' } catch {}