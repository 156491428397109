import {EllipsisIcon} from '@primer/octicons-react'
import {Box} from '@primer/react'

import {useCurrentLineHeight} from '../../../../hooks/use-current-line-height'
import type {SetStickyLinesType} from '../../../../hooks/use-sticky-lines'
import {
  determineProperOffsetWithDummyDiv,
  expandRow,
  getActualLineNumberBinarySearch,
  useCollapsedLinesSubscription,
  useIsLineCollapsed,
} from '../../../../utilities/lines'
import {HighlighterElement} from './CodeLine'
import type {CodeLineData} from './hooks/use-code-lines'

export function CodeFoldingEllipsisOverlay({
  linesData,
  onLineStickOrUnstick,
  setIsCollapsed,
  tabSize,
  extraLeftPadding,
  contentWidth,
}: {
  linesData: CodeLineData[]
  tabSize: number
  contentWidth?: number
  onLineStickOrUnstick?: SetStickyLinesType
  setIsCollapsed?: () => void
  extraLeftPadding?: number
}) {
  const collapsedRows = useCollapsedLinesSubscription()
  const collapsedRowsArray = [...collapsedRows.keys()]
  const lineHeight = useCurrentLineHeight('react-line-numbers')

  return (
    <>
      {collapsedRowsArray.map(lineNumber => {
        const actualLineNumber = getActualLineNumberBinarySearch(lineNumber, linesData)
        if (actualLineNumber === undefined || linesData[actualLineNumber]?.ownedSection === undefined) return null
        const lineData = linesData[actualLineNumber]

        const leftOffset = determineProperOffsetWithDummyDiv(
          lineData?.rawText?.length ?? 0,
          lineData?.rawText ?? '',
          tabSize,
        )
        return (
          <Box
            key={`expand-row-ellipsis-${lineNumber}`}
            sx={{
              position: 'absolute',
              top: lineHeight * actualLineNumber,
              pl: '10px',
              height: lineHeight,
              whiteSpace: 'pre',
            }}
          >
            {contentWidth ? (
              <HighlighterElement
                subtle
                lineNumber={lineNumber}
                highlightPosition={{offset: -82, width: contentWidth}}
              />
            ) : null}
            <ExpandRowEllipsis
              codeLineData={lineData}
              setIsCollapsed={setIsCollapsed}
              onLineStickOrUnstick={onLineStickOrUnstick}
              leftOffset={leftOffset + (extraLeftPadding ?? 0)}
            />
          </Box>
        )
      })}
    </>
  )
}

interface ExpandRowEllipsisProps {
  codeLineData: CodeLineData
  leftOffset: number
  setIsCollapsed?: (isCollapsed: boolean) => void
  onLineStickOrUnstick?: SetStickyLinesType
}

export function ExpandRowEllipsis({
  codeLineData,
  leftOffset,
  setIsCollapsed,
  onLineStickOrUnstick,
}: ExpandRowEllipsisProps) {
  const {lineNumber, ownedSection} = codeLineData
  const collapsed = useIsLineCollapsed(lineNumber)

  if (!collapsed) return null

  return (
    <button
      aria-label="Expand row"
      className="Button Button--iconOnly Button--invisible Button--small px-2 py-0 ml-1 border-0 expand-row-ellipsis"
      style={{left: leftOffset}}
      onMouseDown={ev => {
        expandRow(lineNumber)
        setIsCollapsed?.(false)

        if (ownedSection) {
          // eslint-disable-next-line react-compiler/react-compiler
          ownedSection.collapsed = false
          onLineStickOrUnstick?.(codeLineData, true)
        }

        ev.preventDefault()
      }}
    >
      <EllipsisIcon />
    </button>
  )
}

try{ CodeFoldingEllipsisOverlay.displayName ||= 'CodeFoldingEllipsisOverlay' } catch {}
try{ ExpandRowEllipsis.displayName ||= 'ExpandRowEllipsis' } catch {}