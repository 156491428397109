import type {DeferredAST} from '@github-ui/code-view-types'
import type {Repository} from '@github-ui/current-repository'
import {deferredASTPath} from '@github-ui/paths'
import {useFeatureFlag} from '@github-ui/react-core/use-feature-flag'
import type {RefInfo} from '@github-ui/repos-types'
import {verifiedFetchJSON} from '@github-ui/verified-fetch'
import React, {useEffect, useState} from 'react'

import {noVirtualizationThreshold} from '../components/blob/BlobContent/Code/CodeLinesNoVirtualization'

export const EmptyDeferredAST: DeferredAST = {
  stylingDirectives: null,
}

const DeferredASTContext = React.createContext(EmptyDeferredAST)

export function DeferredASTProvider({children, ...props}: React.PropsWithChildren<DeferredAST>) {
  return <DeferredASTContext.Provider value={props}>{children}</DeferredASTContext.Provider>
}

export function useDeferredAST() {
  return React.useContext(DeferredASTContext)
}

function isValidResponse(obj: DeferredAST): obj is DeferredAST {
  return Array.isArray(obj.stylingDirectives)
}

export function useLoadDeferredAST(
  repo: Repository,
  refInfo: RefInfo,
  path: string,
  has404Error: boolean,
  fileLength: number,
): DeferredAST {
  const [state, setState] = useState<DeferredAST>(EmptyDeferredAST)
  const isNonVirtualized = useFeatureFlag('react_blob_overlay')
  const url =
    refInfo && !has404Error
      ? deferredASTPath({
          repo,
          commitish: refInfo.name,
          path,
        })
      : null

  useEffect(() => {
    if (!url) return
    if (fileLength > noVirtualizationThreshold || !isNonVirtualized) {
      setState(EmptyDeferredAST)
      return
    }
    let cancelled = false
    const update = async () => {
      setState(EmptyDeferredAST)
      const response = await verifiedFetchJSON(url)

      if (cancelled) {
        return
      }

      try {
        if (response.ok) {
          const data = await response.json()
          if (data && isValidResponse(data)) {
            setState(data)
          }
        } else {
          setState(EmptyDeferredAST)
        }
      } catch {
        setState(EmptyDeferredAST)
      }
    }

    update()

    return function cancel() {
      cancelled = true
    }
  }, [url, fileLength, isNonVirtualized])

  return state
}

try{ DeferredASTContext.displayName ||= 'DeferredASTContext' } catch {}
try{ DeferredASTProvider.displayName ||= 'DeferredASTProvider' } catch {}