import {SafeHTMLDiv, type SafeHTMLString} from '@github-ui/safe-html'
import {Box} from '@primer/react'

import {scrollLineIntoView} from '../../hooks/use-scroll-line-into-view'
import {CodeLine} from '../blob/BlobContent/Code/CodeLine'
import type {CodeLineData} from '../blob/BlobContent/Code/hooks/use-code-lines'
import {LineNumber} from '../blob/BlobContent/Code/LineNumber'

export function StickyLinesHeader({
  currentStickyLines,
  colorizedLines,
}: {
  currentStickyLines: Map<number, CodeLineData>
  colorizedLines?: SafeHTMLString[] | null
}) {
  const stickyLines = Array.from(currentStickyLines.values())

  return (
    <Box sx={{overflow: 'hidden', display: 'flex'}}>
      <Box className="react-line-numbers" sx={{marginLeft: '2px'}}>
        {stickyLines.map(stickyLine => {
          return <LineNumber key={`sticky-header-line-number-${stickyLine.lineNumber}`} codeLineData={stickyLine} />
        })}
      </Box>
      <div className="react-code-lines">
        {stickyLines.map(stickyLine => {
          if (colorizedLines && colorizedLines[stickyLine.lineNumber - 1]) {
            return (
              <SafeHTMLDiv
                className="react-code-text react-code-line-contents-no-virtualization react-file-line html-div"
                key={`sticky-header-line-${stickyLine.lineNumber}`}
                style={{paddingLeft: '18px'}}
                html={colorizedLines[stickyLine.lineNumber - 1]!}
                onClick={() => scrollLineIntoView({line: stickyLine.lineNumber})}
              />
            )
          }
          return (
            <CodeLine
              key={`sticky-header-line-${stickyLine.lineNumber}`}
              codeLineData={stickyLine}
              stylingDirectivesLine={stickyLine.stylingDirectivesLine}
              codeLineToSectionMap={undefined}
              copilotAccessAllowed={false} // we're not going to show copilot buttons in the sticky lines
              onClick={() => scrollLineIntoView({line: stickyLine.lineNumber})}
              shouldUseInert={false}
            />
          )
        })}
      </div>
    </Box>
  )
}

try{ StickyLinesHeader.displayName ||= 'StickyLinesHeader' } catch {}