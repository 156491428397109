import {BranchInfoBar, BranchInfoBarErrorBanner} from '@github-ui/code-view-shared/components/directory/BranchInfoBar'
import {DirectoryContent} from '@github-ui/code-view-shared/components/directory/DirectoryContent'
import {LatestCommitSingleLine} from '@github-ui/code-view-shared/components/LatestCommit'
import {CurrentTreeProvider} from '@github-ui/code-view-shared/contexts/CurrentTreeContext'
import {useOpenPanel} from '@github-ui/code-view-shared/contexts/OpenPanelContext'
import type {TreePayload} from '@github-ui/code-view-types'
import {ErrorBoundary} from '@github-ui/react-core/error-boundary'
import {Box} from '@primer/react'

import {DirectoryReadmePreview} from './directory/DirectoryContent/DirectoryReadmePreview'
import FolderViewHeader from './headers/FolderViewHeader'

export default function FileTreeViewContent({
  tree,
  showTree,
  treeToggleElement,
}: {
  tree: TreePayload
  showTree: boolean
  treeToggleElement: JSX.Element
}) {
  const {openPanel, setOpenPanel} = useOpenPanel()

  return (
    <CurrentTreeProvider payload={tree}>
      <LatestCommitSingleLine />

      {tree.showBranchInfobar && (
        <ErrorBoundary fallback={<BranchInfoBarErrorBanner />}>
          <BranchInfoBar />
        </ErrorBoundary>
      )}
      <FolderViewHeader showTree={showTree} treeToggleElement={treeToggleElement} />
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
        <DirectoryContent />
        {tree.readme && (
          <DirectoryReadmePreview
            openPanel={openPanel}
            setOpenPanel={setOpenPanel}
            readme={tree.readme}
            stickyHeaderHeight={50}
          />
        )}
      </Box>
    </CurrentTreeProvider>
  )
}

try{ FileTreeViewContent.displayName ||= 'FileTreeViewContent' } catch {}