import type {SharedMarkdownContentProps} from '@github-ui/code-view-shared/components/SharedMarkdownContent'
import {SharedMarkdownContent} from '@github-ui/code-view-shared/components/SharedMarkdownContent'
import {useRef} from 'react'

import SelectAllShortcutButton from '../utilities/SelectAllShortcutButton'

export function MarkdownContent(props: SharedMarkdownContentProps) {
  const markdownContainer = useRef<HTMLDivElement>(null)
  return (
    <>
      <SharedMarkdownContent {...props} ref={markdownContainer} />
      <SelectAllShortcutButton containerRef={markdownContainer} />
    </>
  )
}

try{ MarkdownContent.displayName ||= 'MarkdownContent' } catch {}