import {SpoofedCommitWarning} from '@github-ui/code-view-shared/components/SpoofedCommitWarning'

import {useDeferredMetadata} from '../../../contexts/DeferredMetadataContext'
import {useCurrentBlob} from '../../../hooks/CurrentBlob'
import {BlobLicenseBanner} from './BlobLicenseBanner'
import {InvalidCitationWarning} from './InvalidCitationWarning'
import {OverridingGlobalFundingFileWarning} from './OverridingGlobalFundingFileWarning'

export default function BlobMidBanners() {
  const {
    topBannersInfo: {
      overridingGlobalFundingFile,
      globalPreferredFundingPath,
      showInvalidCitationWarning,
      citationHelpUrl,
    },
  } = useCurrentBlob()

  const {showLicenseMeta} = useDeferredMetadata()

  return (
    <>
      {showLicenseMeta && <BlobLicenseBanner />}
      {showInvalidCitationWarning && <InvalidCitationWarning citationHelpUrl={citationHelpUrl} />}
      <SpoofedCommitWarning />
      {overridingGlobalFundingFile && (
        <OverridingGlobalFundingFileWarning globalPreferredFundingPath={globalPreferredFundingPath} />
      )}
    </>
  )
}

try{ BlobMidBanners.displayName ||= 'BlobMidBanners' } catch {}